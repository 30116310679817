.outerdiv {
    height: calc(100vh - 10vh);
    width: 100%;
    background-color: #dafff8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customfileupload {

    background-color: teal;
    color: #fff;
    font-size: 14px;
    margin-left: -20px;
    padding: 2px 10px;
    margin-right: 15px;
    align-self: center;
    /* padding-right: 20px; */
    border: none;
    cursor: pointer;
    border-radius: 10px;
    padding: 2px 20px;

}


.customfileuploadone {

    background-color: teal;
    color: #fff;
    font-size: 14px;
    padding: 2px 20px;
    margin-right: 15px;
    align-self: center;

    border-radius: 10px;
    cursor: pointer;


}

.left {
    height: 30vw;
    width: 30vw;
    min-height: 300px;
    min-width: 300px;
    background-color: rgb(254, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.logoutbtn {
    height: 2vw;
    width: 2.2vw;
    min-height: 20px;
    font-size: 15px;
    min-width: 100px;
    background-color: rgb(255, 71, 71);
    color: white;
    border: none;
    margin: auto;
    border-radius: 10px;

}

.right {
    height: 30vw;
    width: 30vw;
    min-height: 300px;
    min-width: 300px;
    background-color: #CCFBF1;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
}

.underright {
    height: fit-content;
    width: 30vw;
    min-width: 300px;
    background-color: rgb(194, 255, 255);
    display: flex;
}

.booking {
    background-color: #CCFBF1;
    padding: 1vw;
    line-height: 25px;
    display: flex;
    border-bottom: 2px solid white;
    justify-content: space-between;
}

.bookingfont {
    font-size: 1vw;
    font-weight: 600;
}

.booked {
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 1.4vw;
    text-transform: uppercase;
    color: teal;
    font-weight: 600;
    padding-top: 15px;
}

.profileIMG {
    height: 10vw;
    width: 10vw;
    min-height: 100px;
    min-width: 100px;
    /* background-color: red; */
    background-image: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pngegg.com%2Fen%2Fpng-ehstd&psig=AOvVaw0OQFuRAoVrxXoTOufAcURn&ust=1699237870604000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCMiuhcDoq4IDFQAAAAAdAAAAABAo");
    border-radius: 50%;
    margin: 3vw auto 1vw auto;
    /* background: transparent; */
}

.btndiv {
    height: 20px;
    width: 120px;
    background-color: #fff;
    margin: auto;
    display: flex;
    /* justify-content: space-between; */
}

.profileInformation {
    height: 13vw;
    width: 13vw;
    min-height: 150px;
    min-width: 150px;
    /* background-color: rgb(107, 104, 101); */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.uploadIMG {
    margin: 0 auto;
}

.h1 {
    font-size: 1.3vw;
    padding: 5px 0;
    text-align: center;
}

#fileInput,
#upload {
    display: none;
    border: none;
    height: 0px;
}

.inp {
    height: inherit;
    width: 40%;
    display: none;
}

.h2 {
    font-size: 0.8vw;
    font-weight: 500;
    padding: 2px 0;
    text-align: center;
}

.demo {
    height: 4vw;
    width: 50%;
    min-height: 40px;
    background-color: teal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-right: 1px solid white;
}

.service {
    height: 4vw;
    width: 50%;
    min-height: 40px;
    background-color: teal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-left: 1px solid white;
}

.bookinglist {
    height: fit-content;
    width: 100%;
    background-color: aqua;
}

.nav {
    color: white;
    font-size: 1.2vw;
    margin: auto;
}

.nav:hover
{
    text-decoration: underline;
}

@media (max-width:1024px) {

    .outerdiv {
        height: fit-content;
        flex-direction: column;
        padding: 20px;
        gap: 10px;
    }

    .h1 {
        font-size: 15px;

    }

    .h2 {
        font-size: 10px;
    }

    .nav {

        font-size: 12px;
    }

    .bookingfont {
        font-size: 12px;
        font-weight: 600;
    }

    .booked {

        font-size: 18px;

    }

}