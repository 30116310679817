.Drheader {
    text-align: center;
    background-color: white;
}

.Drheader h1 {
    font-size: 3vw;
    font-weight: 600;
    padding: 3rem;
    color: teal;
    font-family: 'Roboto Slab', serif;

}

.dsouter {
    /* height: calc(100vh - 10vh); */
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7vw;
    flex-wrap: wrap;
    padding: 0vw 3vw 5vw 3vw;
}

.dsbox {
    height: 32vw;
    width: 30vw;
    min-width: 340px;
    min-height: 440px;
    background-color: #CCFBF1;
    text-align: center;
    padding: 25px;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
}

.dsname {
    font-size: 1.7vw;
}

.dsdes {
    font-size: 0.9vw;
    margin: 10px 0;
    color: DarkOrange;
}

.btn1 {
    font-size: 1vw;
    margin-left: 3%;
    background-color: white;
    border: none;
}

.img {
    width: 26vw;
    height: 15vw;
    min-width: 320px;
    min-height: 190px;
    margin-top: 3vw;
    margin: auto;
}

.btndiv {
    padding: 11px;
    width: 200px;
    margin: auto;
    border-radius: 1rem;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: teal;
    color: white;
    font-size: 14px;
    transition: transform 0.2s;
}

.btndiv:hover{
    transform: scale(1.1);
}
.a {
    margin: auto;
    text-decoration: none;
}

.btn {
    bottom: 0;

}

@media (max-width:1240px) {
    .dsdes {
        font-size: 15px;
        margin: 10px 0;
    }
}

@media (max-width:1026px) {
    .Drheader h1 {
        font-size: 25px;
        font-weight: 600;
        padding: 1rem;

    }

    .dsname {
        font-size: 20px;
    }

    .btn1 {
        font-size: 15px;
        margin-left: 3%;
        background-color: white;
        border: none;
    }

    .img {
        margin-top: 30px;
    }
}