.password-reset-container {
    max-width: 400px;
    margin: 20vh auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.password-reset-container label {
    display: block;
    margin-bottom: 8px;
}

.password-reset-container input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttonfg {
    color: #fff;
    padding: 5px 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.success-message {
    color: green;
    margin-top: 16px;
}
@media(max-width:640px)
{
    .password-reset-container{
        max-width: 95vw;
    }
}