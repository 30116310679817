@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
.testimonialheader{
    text-align:center;
    /* font-family: ; */
}

section{
    background-color: white;
}
.imagesection {
    width:100%;
    display:flex;
    flex-direction:row;
    align-items: flex-start;
    gap:5px;
}
.imagesection > img{
 width:3rem;
 height:3rem;
 object-fit: contain;
 margin:unset;
 border-radius: 50%;
}
.nameheading {
    font-size: 19px;
    font-weight: 500;
}
.designationheading {
    font-size: 15px;
    font-weight: 300;
    color: rgb(92, 92, 92);
}
.testimonialcard{
    max-width:95%;
    border:1px solid rgb(255, 255, 255,0.7);
    display:flex;
    flex-direction: column;
    align-items:center;
    gap:10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding:10px;
    box-sizing: border-box;
    margin:auto;
    min-height: 100%;
}
.cardsection{
    display:flex;
    flex-direction:row;
    width:80%;
    min-height:100%;
    /* border:1px solid red; */
    margin:auto;
    padding:7px 0px;
    gap:10px !important;
    cursor:-webkit-grab;
    /* overflow:hidden;
    overflow-x: hidden; */
}
.slick-slide{
    min-height:100%;
    height: unset;
}
.cardbodysection p{
    font-size:14px;
    text-align:unset;
    color: rgb(61, 61, 61);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.cardbodysection h2,.cardbodysection h4{
    text-align:center;
}
.cardbodysection{
    display:flex;
    flex-direction: column;
    gap:7px;
}
.cardbodysection h4{
    color: rgb(76, 76, 76);
}


.slick-prev:before, .slick-next:before {
    display:none;
}
.countersection{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    width:100%;
}
.slick-track {
    display:flex !important;
}
.slick-slide {
    height:unset !important;
    min-height:100% !important;
}
.slick-slide > div{
    display:flex;
    height: 100%;
}
.firstcountersection,.secondcountersection{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size:24px;
    font-weight: 650;
    font-family: "M PLUS Rounded 1c", sans-serif;
    
}
.counterdata{
    color: rgb(100, 100, 100);
    font-weight: 600;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size:17px;
}
.counteronly {
    background-color: rgba(255, 255, 255, 0.7);
    min-width: max-content;
    /* min-height: 12vw; */
    border-radius: 13px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    font-weight:520;
    padding: 0px 10px;
    width:100%;
}
.slick-dots li button:before {
    font-size:10px !important;
    color: blue !important;
}
.slick-dots li button:before {
    color: teal !important; 
  }
  
  .slick-dots li.slick-active button:before {
    color: darkcyan !important; 
  }
  

@media (max-height: 460px) and (min-width: 768px) and (max-width: 1023px){
    section{
        padding-top: 80px;
    }
}
@media screen and (max-device-width: 480px)  {
    .testimonialheader{
        font-size:25px;
    }
    .cardbodysection p{
        font-size:12px;
    }
    .nameheading {
        font-size:15px;
    }
    .designationheading{
        font-size:13px;
    }

}