.outerss {
    /* height: 90vw; */
    width: 100%;
    background-color: #CCFBF1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headingss {
    width: 35vw;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.space {
    height: 4vw;
    width: 35vw;
}

.about {
    width: 50vw;
    height: fit-content;
    margin: 1vw auto;
    padding: 2vw 6vw 2vw 0;
    font-size: 18px;
    text-align: justify;
    border-radius: 2rem;
    /* background-color: #93ead7; */
}

.demoBookbtn {
    height: 3vw;
    width: 12vw;
    background-color: teal;
    color: white;
    border: black;
    border-radius: 10px;
    font-size: 1.1vw;
}

.h1 {
    font-size: 2vw;
    text-decoration: underline;
}

.cabimg {
    height: 45vw;
    width: 65vw;
    margin-top: 40px;
    border-radius: 10px;
    justify-content: center;
}

@media (max-width:1026px) {
    .headingss {
        height: 3rem;
        width: 22rem;
    }

    .about {
        width: 25rem;
    }

    .cabimg {
        height: 25rem;
        width: 40rem;
    }

    .h1 {
        font-size: 25px;
    }

    .demoBookbtn {
        height: 35px;
        width: 150px;
        font-size: 14px;
    }

}

@media (max-width:670px) {
    .headingss {
        height: 3rem;
        width: 20rem;
    }

    .about {
        width: 22rem;
        margin: 10px 0;
    }

    .outerss p {
        font-size: 15px;
        padding: 7px;
    }
    .h1 {
        font-size: 20px;
    }

    .cabimg {
        height: 15rem;
        width: 21rem;
        margin-top: 2px;
    }

    .outerss {
        height: fit-content;
    }

}