.header {
  height: 9vh;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(234, 234, 234);
  display: flex;
  align-items: center;
}

.logo {
  height: auto;
  width: 6vh;
  /* min-width: 150px; */
  margin-left: 8vw;
  /* background-color: aquamarine; */
  /* padding-top: 0.7rem; */
  margin-top: 4px;
}

.navlist {
  height: 25px;
  margin: 0 8vw 0 auto;

}

ul {
  display: flex;
  justify-content: center;
  gap: 4vw;
  font-size: 1vw;
  font-weight: 500;
}

.li,
.li1 {

  text-decoration: none;
  list-style: none;
  color: black;

  .navkink {

    &:link,
    &:visited {
      display: inline-block;
      text-decoration: none;
      font-size: 18px;

      transition: color 0.3s linear;
    }

    &:hover,
    &:active {
      color: #319795;
    }
  }
}

.phome {
  font-size: 1vw;
  font-weight: bold;
}

.icon {
  font-size: 30px;
  margin-right: 20px;
}

.iconbtn1,
.iconbtn2 {
  height: fit-content;
  width: fit-content;
  background-color: white;
  border: none;
  display: none;
}

@media (max-width:768px) {
  .logo {
    height: auto;
    /* min-width: 80px; */
    margin-left: 10px;
    /* background-color: aquamarine; */
    /* padding-top: 0.7rem; */
  }
}

@media (max-width:420px) {
  .logo {
    /* width: 80px; */
    margin-left: 10px;
    /* background-color: aquamarine; */
    /* padding-top: 0.7rem; */
    margin-top: 0;
  }
}

/* @media (max-width:1444px) {

  li {
    font-size: 23px;
  }
} */

@media (max-width:1226px) {

  .phome {
    font-size: 13px;

  }
}

@media (max-width:900px) {

  .li {
    display: none;
  }

  .li1 {
    display: block;
  }

  .iconbtn1 {
    display: block;
    margin-left: 1rem;
  }

  .header2 {
    height: 100vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
  }

  ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 7vw;
  }

  .iconbtn3 {
    margin-left: 80%;
    height: fit-content;
    width: fit-content;
    background-color: white;
    border: none;
    margin-top: 2rem;
  }

  .iconbtn2 {
    display: none;
  }

  .logo2 {
    display: none;
  }
}