.suouter {
    min-height: 90vh;
    width: 100%;
    background-color: fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.maindiv {
    margin: 20px auto;
    height: fit-content;
    width: fit-content;
    background-color: #fff;
    display: flex;
    /* border: 1px solid black; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
}

.err {
    color: red;
    font-size: 15px;
    font-weight: 500;}

.box {
    height: fit-content;
    width: fit-content;
}


.right {
    background-color: #fff;
    padding: 1vw;
    padding-top: 1.1vw;
    border-radius: 10px;
}

.logo {
    height: 7vw;
    width: 100%;
    background-color: aqua;
}

.h1 {
    font-size: 1vw;
    font-weight: 500;
    margin-bottom: 0.1vw;
}

.input2 {
    height: 2.2vw;
    width: 24vw;
    margin-bottom: 0.5vw;
    border-radius: 5px;
    border: 1px solid black;
    padding-left: 15px;
    font-size: 0.9vw;
}

.loginbtn {
    height: 2.9vw;
    /* background-color: rgb(149, 149, 219); */
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.btn {
    height: 2.3vw;
    width: 100%;
    border-radius: 10px;
    border: 1px solid black;
}

.p {
    font-size: 1vw;
    color: white;
}

.p2 {
    margin-top: 10px;
    font-size: 1vw;
    padding-bottom: 5px;
}

.img {
    height: 21vw;
    width: 25vw;
}

@media (max-width:1022px) {
    .right{
        padding: 2vw;
    }
    .left {
        display: none;
    }

    
    .h1 {
        font-size: 3vw;
        font-weight: 500;
        margin-bottom: 0.1vw;
    }

    .input2 {
        height: 5vw;
        width: 60vw;
        font-size: 2.5vw;
    }

    .loginbtn {
        height: 65px;
        width: 240px;
        /* background-color: rgb(149, 149, 219); */
        display: flex;
        align-items: flex-end;
        /* justify-content: space-between; */
        gap: 40px;
    }

    .btn {
        height: 40px;
        width: 170px;
        border-radius: 10px;
    }

    .p {
        font-size: 20px;
        color: white;
    }

    .p2 {
        font-size: 20px;
    }
    
}
@media (max-width: 640px)
{
    .right{
        padding: 4vw;
    }
    .input2 {
        height: 9vw;
        width: 75vw;
        font-size: 4vw;
    }
    .h1 {
        font-size: 4.5vw;
        font-weight: 500;
        margin-bottom: 0.1vw;
    }
    .fgpass {
        font-size: 15px;
    }
    .loginbtn {
        height: 12vw;
    }
    .btn {
        height: 35px;
        width: 80px;
        border-radius: 10px;
    }
    .p {
        font-size: 15px;
    }
    .p2 {
        font-size: 15px;
    }

}