.outernews {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;

}

.newscontent {
  height: 28vw;
  width: 26vw;
  min-height: 330px;
  min-width: 265px;
  background-color: #CCFBF1;
  border-radius: 1rem;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vw;
}

.img {
  height: 13vw;
  width: 24vw;
  min-height: 185px;
  min-width: 250px;
  background-color: white;
  border-radius: 1rem;
}

.about {
  height: fit-content;
  width: 24vw;
  min-width: 245px;
  background-color: #CCFBF1;
  margin: 0vw 0.2vw;
  border-radius: 5px;
}

.p {
  font-size: 0.8vw;
  padding: 5px;
}
.c1 {
  font-size: 1vw;
  font-weight: 600;
}
.link{
  font-size: 12px;
  color: #317c6b;
  text-decoration: underline;
}
@media(max-width: 1280px)
{
  .p{
    font-size: 0.7vw;
  }
}
@media (max-width:1022px) {
  .p {
    font-size: 8px;
    padding: 5px;
  }
  .link{
    font-size: 10px;
  }
  .outernews {
    height: fit-content;
    width: 100%;
  }
}