.outervs {
    height: fit-content;
    width: 100%;
    background-color: white;
}
.centeredTitle {
    text-align: center;
}
.bulletList {
    list-style-type: circle; /* You can change the bullet style here (e.g., square, circle) */
    margin-left: 10px; /* Adjust the left margin as needed */
}

.bulletList li {
    display: block;
}
.vsimg {
    height: 25vw;
    width: 40vw;
    min-height: 200px;
    min-width: 300px;
    /* background-color: rgb(13, 98, 173); */
    margin: auto;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
}

.content {
    height: fit-content;
    width: 60vw;
    margin: auto;
    /* background-color: aquamarine; */
}

.q {
    font-size: 2vw;
    padding: 1vw;
    border-radius: 20px;
    background-color: white;
    margin: 20px 0;
    margin-bottom: 10px;
    text-decoration: underline;
    color: teal;
}

.ans {
    text-align: center;
    font-size: 1.2vw;
    padding: 15px;
    border-radius: 20px;
    background-color: aliceblue;
    margin: 10px 0;
}

.space {
    height: 5vw;
}

@media (max-width:1026px) {




    .q {
        font-size: 20px;
        padding: 15px;
        border-radius: 20px;
        background-color: #93ead7;
        margin-bottom: 10px;
    }

    .ans {

        font-size: 13px;

    }
}

@media (max-width:600px) {
    .vsimg {
        /* height: 150px;
        width: 300px; */
        margin: auto;
    }

    .content {
        height: fit-content;
        width: 330px;
        margin: auto;
        /* background-color: aquamarine; */
        padding: 20px 0;
    }

    .q {
        font-size: 20px;
        padding: 15px;
        border-radius: 20px;
        background-color: #93ead7;
        margin-bottom: 10px;
    }

    .ans {
        padding: 15px;
        border-radius: 20px;
        margin-bottom: 10px;
        background-color: aliceblue;
    }
}