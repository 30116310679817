/* styles.css */
body {
    font-family: Arial, sans-serif;
}

.form {
    max-width: 500px;
    margin: 0px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.outerdiv {
    background-color: #CCFBF1;
    padding: 20px;
}

.h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    padding-top: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

.discount {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
    color: green;
}

.checkoutbtn {
    display: flex;
    justify-content: center;
}

.payment {
    height: 20px;
    width: 100%;
    /* background-color: lightgreen; */
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.paymentbtn {
    padding: 5px 12px;
    border: none;
    background-color: rgb(28, 163, 163);
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.trid {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 5px;

}

.inputtr {
    width: 85%;
    padding: 10px;
    /* margin-top: 100px; */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.qrimg {
    height: 300px;
    width: auto;
}

.checkoutbtn1 {
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    color: red;
}

.input[type="text"],
.input[type="tel"],
.input[type="number"],
.input[ type="date"],
select,
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input[type="file"] {
    width: 100%;
    margin-bottom: 15px;
}

.chek {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.inputchek {
    margin-bottom: 6px;
    margin-right: 5px;
}

.button {
    background-color: teal;
    color: #fff;
    padding: 10px 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: teal;
}

/* Optional: Style checkbox and radio inputs */
.input[type="checkbox"] {
    margin-right: 5px;
}

.img {
    height: 300px;
}