.Drheader {
    text-align: center;
}

.Drheader h1 {
    font-size: 3vw;
    font-weight: 600;
    padding: 3rem;
    color: teal;
    font-family: 'Roboto Slab', serif;
}

.headertm {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    padding-bottom: 30px;
}

.menber {
    height: 22vw;
    width: 18vw;
    min-height: 230px;
    min-width: 210px;
    background-color: #CCFBF1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2rem;
    margin: 0 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
}

.img {
    height: 9vw;
    width: 9vw;
    min-height: 90px;
    min-width: 90px;
    background-color: rgb(87, 69, 42);
    border-radius: 50%;
    margin-top: 10px;
}
.text {
    min-height: 92px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name {
    margin: 0.6rem 1rem 0rem 1rem;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    background: linear-gradient(to right, #38b2ac, #285a5e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}
.qua {
    margin: 0rem 1rem;
    text-align: center;
    line-height: 28px;
    color: rgb(119, 119, 119);
}
.mail{
    margin: 0rem 1rem;
    text-align: center;
    font-size: 15px;
    color: rgb(119, 119, 119);
}
.socialicons {
    height: 3vw;
    width: 90%;
    margin-top: 2vw;
    background-color: #aaede0;
    display: flex;
    align-items: center; 
    border-radius: 25px;
    justify-content: center;
    gap: 2.2vw;
}

.icondiv {
    height: 2.7vw;
    width: 2.7vw;
    min-height: 40px;
    min-width: 40px;
    /* border: 2px solid teal;
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons {
    font-size: 26px;
    color: rgb(121, 121, 121);
    transition: transform 0.2s;
}
.icons:hover {
    transform: scale(1.1);
}
@media (max-width:1280px)
{
    .text{
        min-height: 60px;
    }
    
}
@media (max-width:1024px)
{
    .name{
        font-size: 14px;
        margin: 0.4rem 1rem 0rem 1rem;
    }
    .qua{
        font-size: 14px;
        margin: 0rem 1rem;
        text-align: center;
        line-height: 20px;
    }
    .menber {
        height: 24vw;
    }
}
@media (max-width:1024px){
    .socialicons{
        height: 4vw;
    }
}
@media (max-width:900px) {
    .socialicons {
        height: 5vw;
        margin-top: 20px;
        gap: 20px;
    }

    .headertm {
        gap: 30px;
    }

    .icons {
        font-size: 20px;
    }

    .Drheader h1 {
        font-size: 25px;
        font-weight: 600;
        padding: 1rem;

    }
}
@media (max-width:600px) {
    .socialicons {
        height: 7vw;
    }
}
@media (max-width:450px) {
    .socialicons {
        height: 10vw;
    }
}